import { Add } from '@mui/icons-material'
import { IconButton, Tooltip } from '@mui/material'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import MoreHorizRounded from '@mui/icons-material/MoreHorizRounded'
import { useQuery } from '@tanstack/react-query'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'

import DefaultMenu, { IMenuItemProps } from 'app/components/menu/DefaultMenu'
import Search from 'app/components/search/Search'
import { PageContentContainer } from 'app/components/styles'
import DataTable from 'app/components/table/table/table'
import TableHeader from 'app/components/table/tableHeader/TableHeader'
import { ContentBody } from 'app/styles'
import { ITableHeadCell, Order } from 'core/models/table'
import { fecthProduct } from 'core/querryes/product/productQuerry'
import theme from 'core/theme/theme'
import DataTablePagination from 'app/components/table/pagination/pagination'
import RegisterProductModal from 'app/components/modals/productModal/registerProductModal'
import CardProduct from './cardProduct/cardProduct'
import { SET_SWITCH_STATE } from 'core/redux/slices/saleSlice/saleSlice'
import { RootState } from 'core/redux/store'
import ProductDetailsModal from 'app/components/modals/detailsModal/productDetails/ProductDetailsModal'
import EditProductModal from 'app/components/modals/productModal/editProductModal'
import NewDefaultFilter from 'app/components/filter/newDefaultFilter'

const head: ITableHeadCell[] = [
    { name: 'name', label: 'Nome', align: 'left' },
    { name: 'amount', label: 'Quantidade', align: 'left' },
    { name: 'sku', label: 'Código do produto', align: 'left' },
    { name: 'priceCost', label: 'Preço de Custo', align: 'center' },
    { name: 'resalePrice', label: 'Preço de Revenda', align: 'center' },
    { name: 'priceTag', label: 'Preço de Etiqueta', align: 'center' },
    { name: 'statusProduct', label: 'Status', align: 'center' },
    { name: 'actions2', label: 'Opções', align: 'left' }
]

const filterItems: any[] = [
    { name: 'Nome', value: 'name', type: 'texto' },
    { name: 'Código do produto', value: 'sku', type: 'texto' },
    {
        name: 'Status do produto',
        value: 'statusProduct',
        options: [
            { key: 'Disponível', value: 'AVAILABLE' },
            { key: 'Acabando', value: 'LOW_STOCK' },
            { key: 'Acabou', value: 'OUT_OF_STOCK' }
        ]
    }
]

function Product() {
    const dispatch = useDispatch()
    const { switchState } = useSelector((state: RootState) => state.sale)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [page, setPage] = useState(0)
    const [isOpenRegisterModal, setIsOpenRegisterModal] = useState(false)
    const [isOpenEditModal, setIsOpenEditModal] = useState(false)
    const [count, setCount] = useState(0)
    const [order, setOrder] = useState<Order>('asc')
    const [orderBy, setOrderBy] = useState('name')
    const [filterModal, setFilterModal] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [details, setDetails] = useState(false)
    const [idProduct, setIdProduct] = useState<string | undefined>()

    const open = Boolean(anchorEl)

    const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
    }

    const handleAccessRowById = (id: string) => {
        setIdProduct(id)
    }

    const menuItems: IMenuItemProps[] = [
        {
            function: () => {
                setIsOpenEditModal(true)
                handleCloseMenu()
            },
            label: 'Editar produto'
        },
        {
            function: () => {
                setDetails(true)
                handleAccessRowById(idProduct || '')
                handleCloseMenu()
            },
            label: 'Detalhes do produto'
        }
    ]

    const [searchParams, setSearchParams] = useSearchParams()
    const name = searchParams.get('name') || ''
    const sku = searchParams.get('sku') || ''
    const statusProduct = searchParams.get('statusProduct') || ''

    const { data, isLoading, isSuccess, refetch } = useQuery({
        queryKey: [
            'product',
            page,
            rowsPerPage,
            orderBy,
            order,
            name,
            sku,
            statusProduct
        ],
        queryFn: () =>
            fecthProduct(
                page,
                rowsPerPage,
                orderBy,
                order,
                name,
                sku,
                statusProduct
            )
    })

    const handleSkuChange = (value: string | undefined) => {
        const newParams = new URLSearchParams(searchParams)
        if (value) {
            newParams.set('sku', value ?? '')
        } else {
            newParams.delete('sku')
        }
        setSearchParams(newParams)
    }

    useEffect(() => {
        if (isSuccess && data) {
            setCount(data.totalElements)
        }
    }, [isSuccess, data])

    const handleClickSwitch = () => {
        dispatch({ type: SET_SWITCH_STATE, payload: !switchState })
    }

    const selectedProduct = data?.content.find(
        (collection: any) => collection.id === idProduct
    )
    const [haveImagesEdit, setHaveImagesEdit] = useState(false)
    return (
        <PageContentContainer>
            <TableHeader
                filterBtn
                filterBtnAction={() => setFilterModal(true)}
                switchActionFunction={handleClickSwitch}
                mainActionFunction={() => setIsOpenRegisterModal(true)}
                mainActionLabel="Cadastrar Produto"
                mainIcon={<Add sx={{ color: theme.COLORS.YELLOW2 }} />}
                status={true}
                extraComponents={
                    <Search
                        searchPlaceHolder="Código do produto..."
                        type="text"
                        querrySearching={isLoading}
                        querry={sku}
                        onChange={handleSkuChange}
                    />
                }
            />
            <ContentBody>
                {switchState ? (
                    <CardProduct refetch={refetch} data={data?.content} />
                ) : (
                    <DataTable
                        head={head}
                        data={data?.content}
                        order={order}
                        orderBy={orderBy}
                        setOrder={setOrder}
                        setOrderBy={setOrderBy}
                        isLoading={isLoading}
                        accessRowById={handleAccessRowById}
                        menu={
                            <Tooltip title="Opções">
                                <IconButton onClick={handleClickMenu}>
                                    <MoreHorizRounded />
                                </IconButton>
                            </Tooltip>
                        }
                    />
                )}
                <DefaultMenu
                    anchor={anchorEl}
                    menuItems={menuItems}
                    onClose={handleCloseMenu}
                    status={open}
                />
                {selectedProduct && details && (
                    <ProductDetailsModal
                        product={selectedProduct}
                        isOpen={details}
                        onClose={() => setDetails(false)}
                        onOpen={() => setDetails(true)}
                    />
                )}
                <RegisterProductModal
                    refetch={refetch}
                    isOpen={isOpenRegisterModal}
                    onClose={() => setIsOpenRegisterModal(false)}
                    onOpen={() => setIsOpenRegisterModal(true)}
                />
                {selectedProduct && isOpenEditModal && (
                    <EditProductModal
                        setHaveImagesEdit={setHaveImagesEdit}
                        product={selectedProduct}
                        isOpen={isOpenEditModal}
                        onClose={() => setIsOpenEditModal(false)}
                        onOpen={() => setIsOpenEditModal(true)}
                        refetch={refetch}
                    />
                )}
                <DataTablePagination
                    setPage={setPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    rowsPerPage={rowsPerPage}
                    count={count}
                />
                <NewDefaultFilter
                    isOpen={filterModal}
                    items={filterItems}
                    onClose={() => setFilterModal(false)}
                    onOpen={() => setFilterModal(true)}
                    title="Filtrar Produtos"
                    changePage={setPage}
                />
            </ContentBody>
        </PageContentContainer>
    )
}

export default Product
