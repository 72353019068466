import React, { useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'core/redux/store';
import ReactToPrint from "react-to-print";

import { formatCurrencyBR, formatDateWithTime, formatDocument } from 'core/utils/globalFunctions'

import './style.css'
import { Button } from '@mui/material';

interface ICupomFiscalProps {
    newSale?: any
    items?: any
    paymentMethods?: any
    dateSale?: string
    saleCode?: string
}

export const CupomFiscal = React.forwardRef((props: ICupomFiscalProps) => {
    const { newSale, items, paymentMethods, dateSale, saleCode } = props
    const invoiceRef = useRef<HTMLDivElement>(null)
    const {
        list,
        paymentToSale,
        percentageDiscount,
        fixedDiscount,
        salePriceType,
        autoCompleteNames
    } = useSelector((state: RootState) => state.sale)

    const date = new Date()
    const formattedDate = date.toLocaleDateString()
    const formattedTime = date.toLocaleTimeString()

    const totalValue = list.reduce(
        (acc, item) =>
            salePriceType === 'ATACADO'
                ? acc + (item.resalePrice ?? 0) * item.quantity
                : acc + (item.priceTag ?? 0) * item.quantity,
        0
    )

    const discountedValue =
        percentageDiscount > 0
            ? totalValue * (1 - percentageDiscount / 100)
            : totalValue - fixedDiscount

    const totalPaid = paymentToSale.reduce(
        (acc, item) => acc + (item.value ?? 0),
        0
    )

    const totalValueItems = useMemo(() => {
        if (items) {
            return items.reduce(
                (acc: any, item: any) =>
                    acc + (item.unitaryValue ?? 0) * item.itemSalesAmount,
                0
            )
        } else return undefined
    }, [items])

    const formatFilial = (filialName: string) => {
        switch (filialName) {
            case 'campina-grande':
                return 'Campina Grande';
            case 'guarabira':
                return 'Guarabira';
            case 'joao-pessoa':
                return 'João Pessoa';
            default:
                return filialName;
        }
    };
    const filial = localStorage.getItem("filial");

    const agency = formatFilial(filial!!);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <ReactToPrint
                bodyClass="print-agreement"
                content={() => invoiceRef.current}
                trigger={() => (
                    <Button variant='text' sx={{ textAlign: 'center', margin: '20px 0' }}>Imprimir</Button>
                )}
            />
            <div ref={invoiceRef} className="container">
                <p>LBS SEMIJOIAS</p>
                <p>AGÊNCIA: {agency}</p>
                <p>CÓDIGO: {newSale ? newSale.saleCod : saleCode ? saleCode : ''}</p>
                <p>
                    DATA EM {dateSale ? formatDateWithTime(dateSale) : `${formattedDate} - ${formattedTime}`}
                </p>
                <hr />
                <div>
                    <p className="text-info"><span style={{ fontWeight: 600, fontFamily: "Arial" }}>Cliente:</span>{' '} {newSale ? newSale.clientName : autoCompleteNames.clientName}</p>
                    <p className="text-info"><span style={{ fontWeight: 600, fontFamily: "Arial" }}>Colaborador:</span>{' '} {newSale ? newSale.nameCollaborator : autoCompleteNames.collaboratorName}</p>
                    <p>
                        <span style={{ fontWeight: 600, fontFamily: "Arial" }}>CPF/CNPJ:</span>{' '}
                        {formatDocument(
                            newSale
                                ? newSale.clientCpforCnpj
                                : autoCompleteNames.cpfClient
                        )}
                    </p>
                </div>
                <hr />
                <div className="line">
                    <p style={{ fontWeight: 'bold' }}>Tipo da venda</p>
                    <p>{newSale ? newSale.type : salePriceType}</p>
                </div>
                <hr />
                <div className="line">
                    <p style={{ fontWeight: 'bold' }}>COD</p>
                    <p style={{ fontWeight: 'bold' }}>PRODUTO</p>
                    <p style={{ fontWeight: 'bold' }}>UND</p>
                    <p style={{ fontWeight: 'bold' }}>QTDE</p>
                    <p style={{ fontWeight: 'bold' }}>TOTAL</p>
                </div>
                <hr />
                {items
                    ? items.map((item: any, index: number) => (
                        <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                            <div className="line" key={index}>
                                <p style={{ width: "50px" }}>
                                    {item.sku ?? "--"}
                                </p>
                                <p style={{ width: "100px" }}>
                                    {item.productName}{' '}
                                    {item.characteristicsDescription
                                        ? '- ' + item.characteristicsDescription
                                        : ''}
                                </p>
                                <p style={{ width: "100px" }}>{formatCurrencyBR(item.unitaryValue)}</p>
                                <p style={{ width: "50px" }}>{item.itemSalesAmount}</p>
                                <p style={{ width: "50px" }}>
                                    {formatCurrencyBR(
                                        item.unitaryValue * item.itemSalesAmount
                                    )}
                                </p>
                            </div>
                            <hr />
                        </div>

                    ))
                    :
                    (list.map((item, index) => (
                        <div key={index}>
                            {item.caracteristicsRequest.length > 0 ? (
                                item.caracteristicsRequest.map((characteristic, charIndex) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                                        <div className='line' key={`${index}-${charIndex}`}>
                                            <p style={{ width: "50px" }}>
                                                {item.sku ?? "--"}
                                            </p>
                                            <p style={{ width: '100px' }}>
                                                {item.productName} {characteristic.description ? "- " + characteristic.description : ""}
                                            </p>
                                            <p style={{ width: "100px" }}>
                                                {formatCurrencyBR(
                                                    salePriceType === "ATACADO" ? item.resalePrice : item.priceTag
                                                )}
                                            </p>
                                            <p style={{ width: "50px" }}>{characteristic.amountProductCharacteristics}</p>
                                            <p style={{ width: "50px" }}>
                                                {salePriceType === "ATACADO"
                                                    ? formatCurrencyBR(item.resalePrice! * characteristic.amountProductCharacteristics)
                                                    : formatCurrencyBR(item.priceTag! * characteristic.amountProductCharacteristics)}
                                            </p>
                                        </div>
                                        <hr />
                                    </div>
                                ))
                            ) : (
                                <div style={{ display: 'flex', flexDirection: 'column' }} key={index}>
                                    <div className='line' key={index}>
                                        <p style={{ width: "50px" }}>
                                            {item.sku ?? "--"}
                                        </p>
                                        <p style={{ width: '100px' }}>
                                            {item.productName}
                                        </p>
                                        <p style={{ width: '100px' }}>
                                            {formatCurrencyBR(
                                                salePriceType === "ATACADO" ? item.resalePrice : item.priceTag
                                            )}
                                        </p>
                                        <p style={{ fontWeight: "bold", width: "50px" }}>{item.quantity}</p>
                                        <p style={{ fontWeight: "bold", width: "50px" }}>
                                            {salePriceType === "ATACADO"
                                                ? formatCurrencyBR(item.resalePrice! * item.quantity)
                                                : formatCurrencyBR(item.priceTag! * item.quantity)}
                                        </p>
                                    </div>
                                    <hr />
                                </div>
                            )}
                        </div>
                    )))
                }
                <b>Totais</b>
                <div className="line">
                    <p>Sub-total</p>
                    <p>
                        {formatCurrencyBR(
                            totalValueItems ? totalValueItems : totalValue
                        )}
                    </p>
                </div>
                <div className="line">
                    <p>Desconto</p>
                    <p>
                        {newSale
                            ? formatCurrencyBR(newSale.discount)
                            : percentageDiscount > 0
                                ? `${percentageDiscount}%`
                                : formatCurrencyBR(fixedDiscount)}
                    </p>
                </div>
                <div className="line">
                    <p>Total</p>
                    <p>
                        {newSale
                            ? formatCurrencyBR(newSale.amount)
                            : formatCurrencyBR(discountedValue)}
                    </p>
                </div>
                <hr />
                <p>Pagamentos</p>
                {paymentMethods
                    ? paymentMethods.map((payment: any, index: number) => (
                        <div className="line" key={index}>
                            <p>{payment.payment}</p>
                            <p>{formatCurrencyBR(payment.value)}</p>
                        </div>
                    ))
                    : paymentToSale.map((payment, index) => (
                        <div className="line" key={index}>
                            <p>{payment.name}</p>
                            <p>{formatCurrencyBR(payment.value)}</p>
                        </div>
                    ))}
                <hr />
                <div className='line'>
                    <p>Total pago</p>
                    <p>
                        {newSale
                            ? formatCurrencyBR(
                                paymentMethods.reduce(
                                    (acc: any, item: any) =>
                                        acc + (item.value ?? 0),
                                    0
                                )
                            )
                            : formatCurrencyBR(totalPaid)}
                    </p>
                </div>
                {!newSale && (
                    <div>
                        <p>Troco</p>
                        <p>
                            {newSale
                                ? formatCurrencyBR(newSale.changeValue)
                                : totalPaid - discountedValue > 0 ? formatCurrencyBR(totalPaid - discountedValue) : "R$ 0,00"}
                        </p>
                    </div>
                )}
                <hr />
                <p style={{ textAlign: "center" }}>
                    Essa nota não tem efeito fiscal
                </p>
                <p style={{ textAlign: "center", marginTop: "100px" }}>
                    Assinatura
                </p>
            </div>
        </div>
    )
})
