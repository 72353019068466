import {
  TCharacteristisRequest,
  TListItem,
  TProductCharacteristics,
} from "app/views/sale/sale";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import DefaultDialog from "../defaultDialog/defaultDialog";
import { Box, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { SET_LIST } from "core/redux/slices/saleSlice/saleSlice";
import { useSelector } from "react-redux";
import { RootState } from "core/redux/store";

interface ICaracteristicsModalProps {
  isOpen: boolean;
  tempProduct: TListItem;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setTempProduct: Dispatch<SetStateAction<TListItem>>;
  setList: Dispatch<SetStateAction<TListItem[]>>;
}

export const EditCaracteristicQtd = ({
  isOpen,
  tempProduct,
  setOpen,
  setList,
  setTempProduct,
}: ICaracteristicsModalProps) => {
  const dispatch = useDispatch();
  const { list } = useSelector((state: RootState) => state.sale);
  const [caracteristicsInfos, setCaracteristicsInfos] = useState<TCharacteristisRequest[]>([]);

  const qtd = useMemo(
    () => caracteristicsInfos.reduce((acc, qtd) => acc + qtd.amountProductCharacteristics, 0),
    [caracteristicsInfos]
  );

  function handleCancel() {
    setOpen(false);
  }

  function handleConfirm() {
    let newList = list.map((item) => item.id === tempProduct.id ? { ...item, quantity: qtd, caracteristicsRequest: caracteristicsInfos } : item);
    dispatch({ type: SET_LIST, payload: newList });
    setCaracteristicsInfos([]);
    setOpen(false);
  }

  const handleQuantityChange = (id: number, quantity: number, description: string) => {
    if (quantity > 0) {
      // Se a quantidade for maior que 0, atualize a quantidade e certifique-se de que a característica está marcada.
      setCaracteristicsInfos((prev) => {
        const exists = prev.some((c) => c.id === id);
        if (exists) {
          return prev.map((c) =>
            c.id === id ? { ...c, amountProductCharacteristics: quantity, description: c.description } : c
          );
        } else {
          return [...prev, { id, amountProductCharacteristics: quantity, description }];
        }
      });
    } else {
      // Se a quantidade for 0, remova a característica da lista.
      setCaracteristicsInfos((prev) => prev.filter((c) => c.id !== id));
    }
  };

  const handleSelectCharacteristic = (characteristic: TProductCharacteristics, isSelected: boolean) => {
    
    if (isSelected) {
      // Se a checkbox for marcada, adicione ou mantenha a característica na lista com a quantidade atual.
      setCaracteristicsInfos((prev) =>
        prev.find((c) => c.id === characteristic.id)
          ? prev
          : [...prev, { id: characteristic.id, amountProductCharacteristics: 1, description: characteristic.description }]
      );
    } else {
      // Se a checkbox for desmarcada, remova a característica da lista.
      setCaracteristicsInfos((prev) => prev.filter((c) => c.id !== characteristic.id));
    }
  };

  useEffect(() => {
    if (tempProduct) {
      setCaracteristicsInfos([...tempProduct.caracteristicsRequest]); // fazer uma cópia profunda
    }
  }, [tempProduct]);


  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isOpen) {
        handleConfirm(); 
      }
    };
    // Adiciona o listener de eventos ao montar o componente
    window.addEventListener("keyup", handleKeyPress);
  
    // Remove o listener de eventos ao desmontar o componente
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleConfirm, isOpen]);
  

  return (
    <DefaultDialog
      title="Adicionar Características"
      disabled={qtd === 0}
      isOpen={isOpen}
      onCloseAction={handleCancel}
      confirmAction={handleConfirm}
      body={
        <>
          <Typography textAlign={"center"}>Total selecionado: {qtd}</Typography>
          <Typography textAlign={"center"}>Escolha as características e as quantidades:</Typography>
          {tempProduct?.caracteristicsInfos?.map((characteristic: TProductCharacteristics) => (
            <Box key={characteristic.id} sx={{ display: "flex", alignItems: "center", p: 1 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!caracteristicsInfos.find((c) => c.id === characteristic.id)}
                    onChange={(event) => handleSelectCharacteristic(characteristic, event.target.checked)}
                  />
                }
                label={`Em estoque: ${characteristic.amount} ${characteristic.characteristics.description}: ${characteristic.description}`}
              />
              <TextField
                label="Qtd"
                type="number"
                value={caracteristicsInfos.find((c) => c.id === characteristic.id)?.amountProductCharacteristics ?? 0}
                onChange={(event) => handleQuantityChange(characteristic.id, Number(event.target.value), characteristic.description)}
                variant="outlined"
                size="small"
                sx={{ ml: 2, width: 70 }}
              />
            </Box>
          ))}
        </>
      }
    />
  );
};
