import {
  TCharacteristisRequest,
  TListItem,
  TProductCharacteristics,
} from "app/views/sale/sale";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import DefaultDialog from "../defaultDialog/defaultDialog";
import { TCharacteristicsDTO, TProductRegister } from "core/models/product";
import {
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";


interface ICaracteristicsModalProps {
  isOpen: boolean;
  fullProduct: any;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setTempProduct: Dispatch<SetStateAction<TListItem>>;
  setSelectedProduct: Dispatch<SetStateAction<TProductRegister | null>>;
  handlePushItem: () => void;
  tempProduct: TListItem;
}

export const CaracteristicsDialog = ({
  isOpen,
  fullProduct,
  setOpen,
  setTempProduct,
  setSelectedProduct,
  handlePushItem,
  tempProduct,
}: ICaracteristicsModalProps) => {
  // ? caracteristicas de quest para o row
  const [caracteristicsInfos, setCaracteristicsInfos] = useState<
    TCharacteristisRequest[]
  >([]
  );

  useEffect(() => {
    if (isOpen && fullProduct) {
      setCaracteristicsInfos(
        fullProduct.productCharacteristics.map((item: TCharacteristicsDTO) => ({
          id: item.id!,
          amountProductCharacteristics: 1,
          description: item.description
        }))
      );
    }
  }, [isOpen, fullProduct]);

  // ? calcula a soma de todas as caracteristicas
  const qtd = useMemo(
    () =>
      caracteristicsInfos.reduce(
        (acc, qtd) => acc + qtd.amountProductCharacteristics,
        0
      ),
    [caracteristicsInfos, fullProduct]
  );

  function handleCancel() {
    setOpen(false);
    setSelectedProduct(null);
    setShouldAddItem(false);
  }

  // ? manda o produto editado para o select pronto para adicionar
  const [shouldAddItem, setShouldAddItem] = useState(false);

  function handleConfirm() {
    if (fullProduct) {
      setTempProduct({
        id: fullProduct.id,
        priceTag: fullProduct.priceTag,
        resalePrice: fullProduct.resalePrice,
        sku: fullProduct.sku,
        productName: fullProduct.name,
        quantity: qtd,
        image: fullProduct.images,
        caracteristicsInfos: fullProduct.productCharacteristics,
        caracteristicsRequest: caracteristicsInfos,
      })
    };
    setShouldAddItem(true);
  }

  useEffect(() => {
    if (shouldAddItem) {
      handlePushItem();
      handleCancel()
    }
  }, [shouldAddItem, tempProduct]);

  const handleQuantityChange = (id: number, quantity: number, description: string) => {
    setCaracteristicsInfos((prev) => {
      if (quantity > 0) {
        // Se a quantidade for maior que 0, atualiza a quantidade e garante que a característica esteja selecionada
        const exists = prev.some((c) => c.id === id);
        if (exists) {
          return prev.map((c) =>
            c.id === id ? { ...c, amountProductCharacteristics: quantity, description: c.description } : c
          );
        } else {
          return [...prev, { id, amountProductCharacteristics: quantity, description }];
        }
      } else {
        // Se a quantidade for 0, remove a característica
        return prev.filter((c) => c.id !== id);
      }
    });
  };

  const handleSelectCharacteristic = (
    characteristic: TProductCharacteristics,
    isSelected: boolean
  ) => {
    setCaracteristicsInfos((prev) => {
      if (isSelected) {
        // Se a checkbox for marcada, adiciona a característica com a quantidade atual ou 1
        return prev.find((c) => c.id === characteristic.id)
          ? prev
          : [...prev, { id: characteristic.id, amountProductCharacteristics: 1, description: characteristic.description }];
      } else {
        // Se a checkbox for desmarcada, remove a característica da lista
        return prev.filter((c) => c.id !== characteristic.id);
      }
    });
  };


  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Enter" && isOpen) {
        handleConfirm(); 
      }
    };
    // Adiciona o listener de eventos ao montar o componente
    window.addEventListener("keyup", handleKeyPress);
  
    // Remove o listener de eventos ao desmontar o componente
    return () => {
      window.removeEventListener("keyup", handleKeyPress);
    };
  }, [handleConfirm, isOpen]);

  return (
    <DefaultDialog
      title="Adicionar Características"
      disabled={qtd === 0}
      isOpen={isOpen}
      onCloseAction={() => handleCancel()}
      confirmAction={() => handleConfirm()}
      body={
        <>
          <Typography textAlign={"center"}>Total selecionado: {qtd}</Typography>
          <Typography textAlign={"center"}>
            Escolha as características e as quantidades:
          </Typography>
          {fullProduct?.productCharacteristics?.map(
            (characteristic: TProductCharacteristics) => (
              <Box
                key={characteristic.id}
                sx={{ display: "flex", alignItems: "center", p: 1 }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        !!caracteristicsInfos.find((c) => c.id === characteristic.id)
                      }
                      onChange={(event) =>
                        handleSelectCharacteristic(characteristic, event.target.checked)
                      }
                    />
                  }
                  label={`Em estoque: ${characteristic.amount} ${characteristic.characteristics.description}: ${characteristic.description}`}
                />
                <TextField
                  label="Qtd"
                  type="number"
                  value={
                    caracteristicsInfos.find((c) => c.id === characteristic.id)
                      ?.amountProductCharacteristics ?? 0
                  }
                  onChange={(event) => {
                    const newQuantity = Number(event.target.value);
                    handleQuantityChange(characteristic.id, newQuantity, characteristic.description);

                    if (newQuantity > 0) {
                      handleSelectCharacteristic(characteristic, true);
                    } else if (newQuantity === 0) {
                      handleSelectCharacteristic(characteristic, false);
                    }
                  }}
                  variant="outlined"
                  size="small"
                  sx={{ ml: 2, width: 70 }}
                />
              </Box>
            )
          )}
        </>
      }
    />
  );
};
